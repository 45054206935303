import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../lib/graphql';
import { Notice } from 'swingby-shared';
import { DocumentType, IResult } from '../../../types';
import { useRouter } from 'next/router';

export const queryNotice = async (noticeId?: string) => {
  const { result } = await graphqlClient.request<IResult<DocumentType<Notice>>>({
    document: QUERY_NOTICE_FIND_BY_ID,
    variables: {
      id: noticeId,
    },
  });
  return result;
};

export const useNotice = () => {
  const { query } = useRouter();
  const { data, isLoading } = useQuery({
    queryKey: ['notice', query['noticeId']],
    queryFn: () => queryNotice(query['noticeId'] as string),
    enabled: !!query['noticeId'],
    suspense: true,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // 15 mins
  });

  return {
    notice: data,
    isLoading,
  };
};

const QUERY_NOTICE_FIND_BY_ID = gql`
  query NoticeFindById($id: MongoID) {
    result: noticeFindById(_id: $id) {
      id
      title
      content
      category
      exposureStartDate
      notionPageId
    }
  }
`;
