import { useInfiniteQuery } from '@tanstack/react-query';
import { DisplayStatus } from 'swingby-shared/dist/enums';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../lib/graphql';
import { NoticeCategory, PER_PAGE_NOTICES } from '../constants';
import { Notice } from 'swingby-shared';
import { DocumentType, IPaginate } from '../../../types';
import { dayjs } from '../../../lib';

interface QueryNoticesInput {
  category: string;
  pageParam?: number;
}

export const queryNotices = async ({ category, pageParam = 1 }: QueryNoticesInput) => {
  const today = dayjs();

  const { result } = await graphqlClient.request({
    document: QUERY_NOTICES_PAGINATION,
    variables: {
      page: pageParam,
      perPage: PER_PAGE_NOTICES,
      sort: 'EXPOSE__START__DATE_DESC',
      filter: {
        exposure: DisplayStatus.Enabled,
        category: category !== 'all' ? category : undefined,
        OR: [
          {
            _operators: {
              exposureStartDate: {
                lte: today,
              },
              exposureEndDate: null,
            },
          },
          {
            _operators: {
              exposureStartDate: {
                lte: today,
              },
              exposureEndDate: {
                gte: today,
              },
            },
          },
        ],
      },
    },
  });

  return result;
};

export const useInfiniteNotices = (category: NoticeCategory) => {
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, isFetching } = useInfiniteQuery<
    IPaginate<DocumentType<Notice>>
  >({
    queryKey: ['notices', category],
    queryFn: ({ pageParam }) => queryNotices({ category, pageParam }),
    getNextPageParam: (lastPage) => (lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.currentPage + 1 : undefined),
    suspense: true,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // 15 mins
  });

  return {
    notices: data ? data.pages.map((page) => page.items).flat() : undefined,
    fetchNextPage,
    hasNextPage,
    isLoading,
    count: data ? data.pages[0].count : 0,
    isFetchingNextPage,
    isFetching,
  };
};

const QUERY_NOTICES_PAGINATION = gql`
  query NoticePagination(
    $page: Int
    $perPage: Int
    $sort: SortFindManyNoticeInput
    $filter: FilterFindManyNoticeInput
  ) {
    result: noticePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        id
        title
        exposureStartDate
        category
      }
      pageInfo {
        hasNextPage
        currentPage
      }
    }
  }
`;
