import { OnlineItem } from '@/atoms/types';
import { OnlineProductItemsQuery, OnlineTicketItemsQuery } from '../type';
import { dayjs } from '@/lib/dayjs';

export const isOnlineTicketItem = (item: OnlineItem): item is OnlineTicketItemsQuery => {
  return item.__typename === 'Ticket';
};

export const isOnlineProductItem = (item: OnlineItem): item is OnlineProductItemsQuery => {
  return item.__typename === 'Product';
};

export const getOnlineItemDiscountValue = (item: OnlineItem): number | undefined => {
  return item?.metaData?.discountRate;
};

export const getOnlineItemPrice = (item: OnlineItem): number => {
  if (isOnlineProductItem(item)) return item.metaData?.packageOriginalPrice || item.price;
  else if (isOnlineTicketItem(item)) return item.price;
  throw new Error('가격정보가 존재하지 않는 상품은 노출할 수 없습니다.');
};

export const getOnlineItemUsingHours = (item: OnlineItem): number => {
  if (isOnlineProductItem(item)) return item.metaData?.packageUsingHours || 0;
  else if (isOnlineTicketItem(item)) return item.usingHours || 0;
  throw new Error('이용시간 정보가 존재하지 않는 상품은 노출할 수 없습니다.');
};

export const getOnlineItemOriginalPrice = (item: OnlineItem) => {
  const normalCost = item?.metaData?.normalCost;

  if (normalCost) return normalCost;

  const itemPrice = getOnlineItemPrice(item);
  const discountValue = getOnlineItemDiscountValue(item);

  if (!discountValue || discountValue === 0) return itemPrice;
  return itemPrice && Math.round((itemPrice * (100 / (100 - discountValue))) / 1000) * 1000;
};

interface OnlineItemDate {
  startDate: string;
  endDate?: string;
  times: [string, string];
}

/**
 * 상품 판매 가능 범위 날짜 구하기 - 온라인 판매 일자 필드가 존재하지 않을 시 salesDate 필드 사용
 */
export const getOnlineItemSalesDate = (item: OnlineItem): OnlineItemDate => {
  const endDate = item.onlineSalesStartDate ? item.onlineSalesEndDate : item.salesEndDate;
  return {
    startDate: dayjs(item.onlineSalesStartDate || item.salesStartDate)
      .startOf('day')
      .toISOString(),
    endDate: endDate ? dayjs(endDate).endOf('day').toISOString() : undefined,
    times: item.onlineSalesTime || item.salesTime,
  };
};

/**
 * 상품 사용 가능 기간 구하기
 */
export const getOnlineItemUsableDate = (item: OnlineItem): OnlineItemDate => {
  return {
    startDate: dayjs(item.salesStartDate).startOf('day').toISOString(),
    endDate: item.salesEndDate ? dayjs(item.salesEndDate).endOf('day').toISOString() : undefined,
    times: item.salesTime,
  };
};
