import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Dayjs } from 'dayjs';
import { Band, Coupon, Park } from 'swingby-shared';
import { Cart, OnlineItem } from './types';
import { DocumentType } from '../types';
import { getOnlineItemPrice, isOnlineProductItem } from '@/pages/ticket/basic-ticket/utils/onlineItem';

const { persistAtom } = recoilPersist();

const initialToken = typeof window === 'undefined' ? null : localStorage.getItem('token') || null;

export const tokenState = atom<string | null>({
  key: 'session-token',
  default: initialToken,
});

export const tokenSelector = selector<string | null>({
  key: 'session-token-selector',
  get: ({ get }) => get(tokenState),
  set: ({ set }, token) => {
    if (typeof token == 'string') {
      set(tokenState, token);
      localStorage.setItem('token', token);
    }
  },
});

export const signupAppleEmailState = atom({
  key: 'signup-apple-email',
  default: '',
});

export const signupAppleIdState = atom({
  key: 'signup-apple-id',
  default: '',
  effects: [persistAtom],
});

export const appleRefreshTokenState = atom<string>({
  key: 'apple_refresh_token',
  default: '',
  effects: [persistAtom],
});

export const bandPrevPageState = atom({
  key: 'band-prev-page',
  default: '',
});

export const selectedTicketDateState = atom<Dayjs | null>({
  key: 'ticket-date',
  default: null,
  effects: [persistAtom],
});

export const selectedTicketTimeState = atom<string | undefined | null>({
  key: 'selected-time',
  default: null,
  effects: [persistAtom],
});

export const onlineItemsState = atom<OnlineItem[]>({
  key: 'online-items',
  default: [],
});

export const onlineItemsSelector = selector<{
  totalPrice: number;
  totalTicketCount: number;
}>({
  key: 'online-items-selector',
  get: ({ get }) => {
    const onlineItems = get(onlineItemsState);

    const totalPrice = onlineItems.reduce((acc, cur) => acc + getOnlineItemPrice(cur), 0);

    const totalTicketCount = onlineItems.reduce((acc: number, cur: OnlineItem) => {
      let ticketQuantity = 0;

      if (isOnlineProductItem(cur)) {
        cur.packageItems.map((packageItem) => {
          if (packageItem.ticket) ticketQuantity += packageItem.quantity;
        });
      } else {
        ticketQuantity = 1; // 단일 티켓일 경우
      }

      return acc + ticketQuantity;
    }, 0);

    return { totalPrice, totalTicketCount };
  },
});

export const startGetPaymentStatusState = atom<boolean>({
  default: false,
  key: 'start_get_payment_status',
});

export const paymentErrorState = atom<boolean>({
  default: false,
  key: 'payment_error',
});

export const paymentErrorMsgState = atom<string>({
  default: '',
  key: 'payment_error_msg',
});

export const cancelPaymentSuccessState = atom<boolean>({
  default: false,
  key: 'cancel_payment_success',
});

export const cancelPaymentErrorState = atom<boolean>({
  default: false,
  key: 'cancel_payment_error',
});

export const cartState = atom<Cart[]>({
  key: 'cart-state',
  default: [],
});

export const parkState = atom<DocumentType<Park> | null>({
  key: 'park-state',
  default: null,
});

export const welcomeCouponModalState = atom<boolean>({
  key: 'welcome-coupon-modal-state',
  default: false,
});

export const clientPhoneState = atom<string | null>({
  key: 'client-phone-state',
  default: null,
  effects: [persistAtom],
});

export const clientUidState = atom<string | null>({
  key: 'client-uid-state',
  default: null,
  effects: [persistAtom],
});

export const clientCouponState = atom<Coupon | null>({
  key: 'client-coupon-state',
  default: null,
});

export const clientCouponAmountState = atom<number | null>({
  key: 'client-coupon-amount-state',
  default: null,
});

export const bandState = atom<Band | null>({
  key: 'band-state',
  default: null,
});

export const openUpdateInactiveModalState = atom<boolean>({
  key: 'open-update-inactive-modal-state',
  default: false,
});

export const appleWalletAdditionModalState = atom<boolean>({
  key: 'apple-wallet-addition-modal-state',
  default: false,
});

export const appleWalletGuideModalState = atom<boolean>({
  key: 'apple-wallet-guide-modal-state',
  default: false,
});
