import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback, useState } from 'react';
import { IResult } from '../../../types';
import { graphqlClient } from '../../../lib/graphql';

const READ_NOTICES = 'read-notices';

const isArrayString = (value: unknown): boolean => {
  if (Array.isArray(value)) {
    return value.every((item) => typeof item === 'string');
  }
  return false;
};

const getReadNoticesLocalStorageData = () => {
  try {
    if (typeof localStorage === 'undefined') return [];
    const localData = JSON.parse(localStorage.getItem(READ_NOTICES) || '[]');
    const result = isArrayString(localData) ? localData : [];
    localStorage.setItem(READ_NOTICES, JSON.stringify(result));
    return result;
  } catch {
    localStorage.removeItem(READ_NOTICES);
    return [];
  }
};

const queryExistUnreadNotice = async (noticeIds: string[]) => {
  const { result } = await graphqlClient.request<IResult<Boolean>, { noticeIds: string[] }>({
    document: EXIST_UNREAD_NOTICE_QUERY,
    variables: { noticeIds },
  });

  return result;
};

export const useReadNotices = () => {
  const [readNotices, setReadNotices] = useState<string[]>(getReadNoticesLocalStorageData);

  const { data: existUnreadNotice } = useQuery({
    queryKey: ['notices', 'unread', readNotices],
    queryFn: () => queryExistUnreadNotice(readNotices),
    initialData: false,
  });

  const onAppendReadNotices = useCallback((newNotices: string[]) => {
    if (typeof localStorage === 'undefined') return;
    setReadNotices((prev) => {
      const cleanedData = Array.from(new Set([...prev, ...newNotices]));
      localStorage.setItem(READ_NOTICES, JSON.stringify(cleanedData));
      return cleanedData;
    });
  }, []);

  const onSetReadNotices = useCallback((newNotices: string[]) => {
    if (typeof localStorage === 'undefined') return;
    localStorage.setItem(READ_NOTICES, JSON.stringify(newNotices));
    setReadNotices(newNotices);
  }, []);

  return {
    existUnreadNotice,
    onAppendReadNotices,
    onSetReadNotices,
  };
};

const EXIST_UNREAD_NOTICE_QUERY = gql`
  query existUnreadNotice($noticeIds: [String]) {
    result: existUnreadNotice(noticeIds: $noticeIds)
  }
`;
