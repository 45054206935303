import Image from 'next/image';
import styled from '@emotion/styled';
import Logo from '../../../public/smob_logo.svg';
import NotificationsIcon from '../../../public/notifications.svg';
import { useRouter } from 'next/router';
import { useReadNotices } from '../../notices/hooks';

const Header = () => {
  const router = useRouter();
  const { existUnreadNotice } = useReadNotices();

  const handleClickNoticeButton = () => router.push('/notices');

  return (
    <HeaderContainer>
      <div>
        <Image src={Logo} width={84} height={24} alt="logo" />
      </div>
      <button type="button" onClick={handleClickNoticeButton}>
        <Image src={NotificationsIcon} layout="fill" alt="notifications" />
        {existUnreadNotice && <div className="readMark" />}
      </button>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  width: 100%;
  max-width: 640px;

  button {
    all: unset;
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 24px;

    .readMark {
      position: absolute;
      width: 8px;
      height: 8px;
      left: 19px;
      top: -2px;
      background-color: #ff2d5a;
      border-radius: 50%;
    }
  }
`;
