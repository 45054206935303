import { NoticeCategory as NoticeCategoryAbstract } from 'swingby-shared';

export const PER_PAGE_NOTICES = 8;

export type NoticeCategory = 'all' | NoticeCategoryAbstract;

export const NoticeCategoryMap: { [category in NoticeCategory]: string } = {
  all: '전체',
  [NoticeCategoryAbstract.General]: '일반',
  [NoticeCategoryAbstract.Park]: '지점',
  [NoticeCategoryAbstract.Update]: '업데이트',
};
