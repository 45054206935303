import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import useWindowSize from '../../../hooks/useWindowSize';

export const MainBannerSkeleton = () => {
  const { width } = useWindowSize();
  return <Skeleton variant="rectangular" animation="wave" sx={{ height: `calc(${Math.min(width, 640)}px / 1.1)` }} />;
};

export const MenusSkeleton = () => {
  const { width } = useWindowSize();
  const widthPerHeight = 0.25;
  const MIN_WIDTH = 360;
  const MAX_WIDTH = 640;
  const MIN_HEIGHT = 50;

  return (
    <MenusSkeletonWrapper>
      {Array.from({ length: 4 }).map((_, index) => {
        if (!width) return;
        return (
          <div key={index}>
            <Skeleton
              variant="circular"
              animation="wave"
              height={(Math.min(width, MAX_WIDTH) - MIN_WIDTH) * widthPerHeight + MIN_HEIGHT}
              sx={{ aspectRatio: '1', minHeight: MIN_HEIGHT, maxHeight: 96 }}
            />
            <Skeleton variant="text" animation="wave" sx={{ margin: '0 auto' }} />
          </div>
        );
      })}
    </MenusSkeletonWrapper>
  );
};

export const AttractionSkeleton = () => {};

const MenusSkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 24px;

  div {
    padding: 5px;
  }
`;
