import 'dayjs/locale/ko';
import dayjsAbstract from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjsAbstract.extend(duration);
dayjsAbstract.extend(objectSupport);
dayjsAbstract.extend(utc);
dayjsAbstract.extend(timezone);
dayjsAbstract.extend(isBetween);
dayjsAbstract.extend(isSameOrBefore);

dayjsAbstract.tz.setDefault('Asia/Seoul');
dayjsAbstract.locale('ko');

/**
 dayjs -> timezone 서울시 고정
 */
export const dayjs = (
  date?: dayjsAbstract.ConfigType,
  format?: dayjsAbstract.OptionType | undefined,
  strict?: boolean | undefined,
) => {
  return dayjsAbstract(date, format, strict).tz();
};

export const dayjsDuration = (time: number, unit?: duration.DurationUnitType | undefined) => {
  return dayjsAbstract.duration(time, unit);
};

/**
 HH:mm 시간 형식 string 데이터 한 시간 더하기
 @example "15:00" -> "16:00" 
 */
export const getTimePlusOneHour = (time?: string | null) => {
  return dayjsAbstract(time || '00:00', 'HH:mm')
    .add(1, 'h')
    .format('HH:mm');
};
